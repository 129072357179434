.footer {
  height: 300px;
  position: relative;
}
.footer .footer-s1 {
  display: flex;
  flex-direction: column;
}
.footer .footer-s1 img {
  width: 244px;
  height: 154.5px;
}
.footer .footer-s1 .footer-s1-ul {
  display: flex;
  gap: 16px;
}
.footer .footer-s1 .footer-s1-ul li {
  background: var(--main-gradiant-color);
  height: 40px;
  width: 40px;
  border-radius: 24.51272964477539px;
}
.footer .footer-s1 .footer-s1-ul li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.footer .footer-s1 .footer-s1-ul li a svg {
  color: var(--main-white);
  width: 20px;
  height: 20px;
}
.footer .footer-s1 .footer-s1-ul li:hover {
  cursor: pointer;
  background: var(--main-white);
  transition: all 0.5s ease-in-out;
}
.footer .footer-s1 .footer-s1-ul li:hover svg {
  color: greenyellow;
}
.footer .footer-s4 p,
.footer .footer-s3 p,
.footer .footer-s2 p {
  margin-bottom: 24px;
}
.footer .footer-s4 ul li,
.footer .footer-s3 ul li,
.footer .footer-s2 ul li {
  padding: 0 0 16px 0;
}
.footer .footer-s4 .footer-s4-phones {
  display: flex;
  gap: 90px;
}
.footer .footer-s4 .footer-s4-phones span {
  width: 150px;
}
@media screen and (max-width: 575px) {
  .footer {
    height: 80vh;
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .footer .footer-s1 .footer-s1-ul {
    position: relative;
    bottom: -28rem;
  }
  .footer .footer-s1 img {
    width: 144px;
    height: 91px;
  }
}
