.error-page .blur-circle {
  position: absolute;
  width: 269.79px;
  height: 269.79px;
  left: 0;
  top: 0px;
  background: rgba(180, 205, 16, 0.637);
  opacity: 0.6;
  filter: blur(118px);
}
.error-page-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 230px;
}
.error-page-content h4 {
  margin-bottom: 36px;
  text-transform: uppercase;
}
.error-page-content img {
  height: 60%;
  width: 70%;
  margin-top: 214px;
  margin-bottom: 48px;
}
.error-page-content button {
  color: #191814 !important;
  padding: 16px 32px !important;
  width: 230px !important;
  border: none !important;
}
