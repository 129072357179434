.contact-us {
  margin-bottom: 190px;
}
.contact-us-map {
  height: 120vh;
  position: relative;
  background: url("../../assets/Img/map.png");
}
.contact-us-map iframe {
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 992px) {
  .contact-us {
    margin-bottom: 60%;
  }
  .contact-us-map {
    background: url("../../assets/Img/map2.png");
  }
}
@media screen and (max-width: 575px) {
  .contact-us {
    margin-bottom: 35rem;
  }
  .contact-us-map {
    background: url("../../assets/Img/map2.png");
  }
}
