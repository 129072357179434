:root {
  --yellow: linear-gradient(180deg, #f8d49a -146.42%, #fad79d -46.42%);
  --orange: #fca61f;
  --black: #242d49;
  --gray: #788097;
  --purple: linear-gradient(180deg, #bb67ff 0%, #c484f3 100%);
  --pink: #399b25;
  --glass: rgba(255, 255, 255, 0.54);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
  --activeItem: #a4c717;
}

.dashboard-App {
  color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  direction: ltr;
}
.dashboard-users {
  overflow-y: scroll;
}
.AppGlass {
  display: grid;
  height: 97%;
  overflow-y: scroll;
  width: 97%;
  border-radius: 2rem;
  gap: 16px;
  grid-template-columns: 11rem auto;
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  .AppGlass {
    grid-template-columns: 10% auto;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 768px) {
  .AppGlass {
    grid-template-columns: 1fr;
  }
}
