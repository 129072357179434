.contact-card {
  height: 486px;
  width: 50%;
  background: #f7f7f7;
  padding: 60px;
  -webkit-box-shadow: 24px 24px 0px 0px rgba(217, 217, 217, 0.4);
  -moz-box-shadow: 24px 24px 0px 0px rgba(217, 217, 217, 0.4);
  box-shadow: 24px 24px 0px 0px rgba(217, 217, 217, 0.4);
  position: absolute;
  top: 20%;
  right: 5rem;
}
.contact-card h3 {
  margin-bottom: 32px;
}
.contact-card p {
  margin-bottom: 49px;
}
.contact-card .contact-cards-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
}
.contact-card .contact-cards-icons .contact-card-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  position: relative;
}
.contact-card .contact-cards-icons .contact-card-icon span:first-of-type {
  padding: 25px;
  border: 1px solid #a4c717;
  -webkit-box-shadow: 11px 13px 0px 0px rgba(82, 82, 82, 1);
  -moz-box-shadow: 11px 13px 0px 0px rgba(82, 82, 82, 1);
  box-shadow: 11px 13px 0px 0px rgba(82, 82, 82, 1);
}

.contact-card .contact-cards-icons .contact-card-icon span svg {
  width: 24px;
  height: 24px;
  color: #a4c717;
}
@media screen and (max-width: 992px) {
  .contact-card {
    width: 670px;
    top: 85%;
    right: 3rem;
  }
}
@media screen and (max-width: 575px) {
  .contact-card {
    display: flex;
    flex-direction: column;
    width: 85%;
    height: 57%;
    left: 2rem;
    top: 90% !important;
    padding: 0 30px;
  }
  .contact-card h3 {
    font-size: 20px !important;
    margin-bottom: 16px;
  }
  .contact-card p {
    margin-bottom: 27px;
  }
  .contact-card .contact-cards-icons {
    display: flex;
    flex-direction: column;
    gap: 37px;
  }
}
