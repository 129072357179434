.services {
  display: flex;
  flex-direction: column;
  gap: 145px;
  margin-bottom: 145px;
}
.services-wrapper {
  display: flex;
  flex-direction: column;
  gap: 145px;
  overflow: hidden;
}
/* ==========================Services====================== */
.services-p1-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));
  grid-auto-flow: dense;
  gap: 38px;
}

@media screen and (max-width: 992px) {
  .services {
    gap: 73px;
  }
  .services-wrapper {
    gap: 73px;
  }
  .services-container {
    display: flex;
    flex-direction: column;
    gap: 73px;
  }
}
@media screen and (max-width: 575px) {
  .services {
    margin-bottom: 40px;
  }
  .services-p1-cards {
    gap: 24px;
  }
}
