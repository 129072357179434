.service-container {
  padding-bottom: 145px;
  position: relative;
}
.service-container:first-of-type {
  padding: 145px 0;
}
.service-container > div {
  height: 100%;
}
.service-container .service-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 81px;
  position: relative;
  height: 100vh !important;
}
.service-container .service-info-img {
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.service-container .service-info-img .img-bg {
  padding: 18px;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  top: 58px;
}
.service-container .service-info-img .img-bg img {
  height: 100%;
  width: 100%;
  position: absolute;
  top: -58px;
}
.service-container .service-info-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  direction: ltr;
}
[dir="rtl"] .service-container .service-info-content {
  direction: rtl;
}
.service-container .service-info-content h3 {
  margin-bottom: 32px;
  color: var(--main-white);
}
.service-container .service-info-content p {
  color: #d9d9d9;
}

#GraphicalImg {
  position: absolute;
  height: 186px;
  width: 56px;
  left: 0;
  bottom: 0;
  border-radius: 0px;
}
.service-blur {
  position: absolute;
  width: 269.79px;
  height: 269.79px;
  left: 0;
  top: 0px;
  background: rgba(180, 205, 16, 0.637);
  opacity: 0.6;
  filter: blur(218px);
}
@media screen and (max-width: 992px) {
  .service-container:first-of-type {
    padding: 73px 0;
    height: 50vh;
  }
  .service-container .service-info-img .img-bg,
  .service-container .service-info-img .img-bg img {
    height: 472px;
  }
}
@media screen and (max-width: 575px) {
  .service-container .service-info {
    grid-template-columns: repeat(1, 1fr);
    gap: 50px;
  }
  .service-container:first-of-type {
    height: 100%;
  }
  .service-container .service-info-img {
    order: 2 !important;
    width: 100%;
    padding: 18px;
  }
  .service-container .service-info-img .img-bg {
    top: 0 !important;
  }
  .service-container .service-info-img .img-bg img {
    order: 1;
    left: 0 !important;
    top: 0 !important;
  }
  .service-container .service-info-content {
    order: 1;
  }
  .service-container .service-info-content h3 {
    margin-bottom: 16px;
    font-size: 22px !important;
    text-align: center;
  }
}
