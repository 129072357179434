.home {
  display: flex;
  flex-direction: column;
  gap: 145px;
  margin-bottom: 145px;
}
/* ==========================Services====================== */
.home-services-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 38px;
}
/* ==========================Experiance====================== */
.home-experiance {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 54px;
}
.home-experiance .home-experiance-img {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: end;
}
.home-experiance .home-experiance-img img {
  width: 100%;
  height: 388px;
}
.home-experiance .home-experiance-info h2 {
  margin-bottom: 16px;
}
.home-experiance .home-experiance-info p {
  margin-bottom: 32px;
}
.home-experiance .home-experiance-info button {
  margin-bottom: 48px;
}
.home-experiance .home-experiance-info .home-experiance-info-stats {
  display: flex;
  gap: 32px;
}
.home-experiance .home-experiance-info .home-experiance-info-stats div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home-experiance
  .home-experiance-info
  .home-experiance-info-stats
  .stat-number {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 43px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: #161515;
}
[dir="rtl"] .home-experiance .home-experiance-img {
  order: 1;
}
/* =========================Why Us======================= */
.home-whyus {
  height: 820px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.home-whyus #myVideo {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
}
.home-whyus .home-whyus-content {
  position: absolute;
  background: #161515;
  opacity: 0.9;
  backdrop-filter: blur(12px);
  width: 50%;
  height: 100%;
  padding: 80px;
}
.home-whyus .home-whyus-content .home-whyus-title h3 {
  margin-bottom: 16px;
  color: var(--main-white);
}
.home-whyus .home-whyus-content .home-whyus-title p {
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 1.5 !important;
  color: #d9d9d9;
  margin-bottom: 50px;
}
.home-whyus .home-whyus-content .home-whyus-items {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.home-whyus .home-whyus-content .home-whyus-items .whyus-item {
  display: flex;
  flex-direction: column;
}
.home-whyus .home-whyus-content .home-whyus-items .whyus-item div {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
}
.home-whyus .home-whyus-content .home-whyus-items .whyus-item div svg {
  width: 24px;
  height: 24px;
  color: var(--main-white);
}
.home-whyus .home-whyus-content .home-whyus-items .whyus-item div p {
  color: var(--main-white) !important;
}
.home-whyus .home-whyus-content .home-whyus-items .whyus-item p {
  margin-bottom: 0;
  color: var(--main-white);
}
/* ==================================================================== */
/* ===========================Medium Devices============= */

@media screen and (min-width: 992px) {
  .home-whyus-video-responsive {
    display: none;
  }
}
/* ===========================Medium Devices============= */
@media screen and (max-width: 992px) {
  .home {
    gap: 73px;
  }
  .home-services-cards {
    display: none;
  }
  .home-experiance {
    grid-template-columns: repeat(1, 1fr);
  }
  .home-experiance .home-experiance-img {
    order: 2;
  }
  .home-experiance-info {
    order: 1;
    padding: 0 55px;
  }

  .home-whyus .home-whyus-content {
    width: 100%;
  }
  .home-whyus-video-responsive {
    height: 820px;
    position: relative;
  }
  .slider-div {
    padding: 0 6px !important;
  }
  .home-whyus-video-responsive #myVideo2 {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
  }
}
/* ===========================Small Devices============= */

@media screen and (max-width: 575px) {
  .home {
    gap: 40px;
    margin-bottom: 72px;
  }
  .home-experiance-info {
    padding: 0;
  }
  .home-whyus .home-whyus-content {
    padding: 20px;
  }
}
