.navbar-nav {
  gap: 32px !important;
  margin-left: 3rem;
}
.nav-link {
  color: #ffffff66 !important;
}
.nav-link-active {
  color: var(--main-white) !important;
}
.nav-link:hover {
  color: var(--main-white) !important;
}

.lang-drop li a {
  color: black !important;
}
/* ===========================Medium Devices============= */
@media screen and (max-width: 992px) {
  .navbar-toggler-icon {
    background-image: url("../../assets/Img/MenuImg.png") !important;
    width: 47px !important;
    height: 47px !important;
    order: 1;
  }
  .navbar-toggler-icon:active {
    border: cornflowerblue;
  }
  .navbar-collapse {
    background: black;
    margin: 0 !important;
  }
  .navbar-nav {
    margin: 5% !important;
    display: flex;
    flex-direction: row;
  }
}
