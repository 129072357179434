.banner {
  position: relative;
  width: 100%;
  height: 1024px;
  direction: ltr;
}

.banner-bg-img {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  z-index: 2;
}
.banner-bg-img-blur {
  position: absolute;
  -webkit-filter: blur(16px);
  -moz-filter: blur(16px);
  -o-filter: blur(16px);
  -ms-filter: blur(16px);
  filter: blur(16px);
  filter: blur(16px);
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 100%;
  width: 55%;
}
.banner .banner-s1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: relative;
  z-index: 3;
}
[dir="rtl"] .banner-s1 {
  direction: rtl;
}
.banner .banner-s1 .happy-clients-container {
  display: flex;
  gap: 110px;
}
.banner .banner-s1 h1 {
  color: var(--main-white) !important;
  margin-bottom: 24px;
  text-transform: capitalize;
}
.banner .banner-s1 p {
  color: #d9d9d9;
}
.banner .banner-s1 > p {
  margin-bottom: 48px;
}
.banner-s2 {
  height: 100%;
}
/* ===========================Medium Devices============= */
@media screen and (max-width: 992px) {
  .banner {
    height: 657px;
  }
  .banner .banner-s1 .happy-clients-container {
    display: none;
  }
  .banner-btn {
    display: none !important;
  }
  .banner .banner-s1 h1 {
    font-size: 42px !important;
  }
}
@media screen and (max-width: 575px) {
  .banner {
    height: 828px;
  }

  .banner-bg-img {
    display: none;
  }
  .banner-bg-img-blur {
    display: none;
  }
  .banner .banner-s1 {
    padding: 180px 30px 80px 30px;
    position: relative;
    height: 420px;
    background: linear-gradient(90deg, #191814e5 100%, #00ffff00 0%);
  }

  .banner .banner-s1 h1 {
    margin-bottom: 16px;
    font-size: 28px !important;
  }

  .banner-s2 {
    height: 421px;
  }
  .banner-bg-img-s2 {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }
}
