.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.login-form {
  width: 100%;
  max-width: 520px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  border-radius: 5px;
  background: var(--main-gradiant-color);
}
.login form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
  padding-bottom: 1rem;
}
.login label,
.login button {
  margin-top: 1rem;
}
.login form input {
  padding: 10px;
  border-radius: 10px;
}
.login form button {
  padding: 10px;
  border-radius: 10px;
}
.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #000;
  color: #fff;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.line {
  display: inline-block;
}
