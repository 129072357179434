.happy-clients {
  display: flex;
  flex-direction: column;
  margin-top: 148px;
  position: relative;
}
.happy-clients p {
  text-transform: capitalize;
}
.happy-clients:first-of-type::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 100px;
  background: #d9d9d9;
  right: -3.5rem;
}
[dir="rtl"] .happy-clients:first-of-type::after {
  right: 12.5rem;
}
.happy-clients .happy-clients-images {
  display: flex;
  margin-bottom: 16px;
}
.happy-clients .happy-client-img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
