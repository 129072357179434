/* paragraph Font */
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700;800&display=swap");
/* Headers Font */
@font-face {
  font-family: "TT Octosquares";
  src: local("TT Octosquares"),
    url("./fonts/TT.Octosquares/TTOctosquares-Regular.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "TT Octosquares";
  src: local("TT Octosquares"),
    url("./fonts/TT.Octosquares/TTOctosquares-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "TT Octosquares";
  src: local("TT Octosquares"),
    url("./fonts/TT.Octosquares/TTOctosquares-DBold.ttf") format("truetype");
  font-weight: bold;
}
/* =================== Arabic Font ================= */
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&display=swap");
/* //react-slick */
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
:root {
  --main-black: #161515;
  --main-background: #f7f7f7;
  --main-gradiant-color: linear-gradient(180deg, #a4c717 0%, #d6db01 100%);
  --main-white: #fff;
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  scroll-behavior: smooth !important;
  cursor: none;
}
html {
  scroll-behavior: smooth !important;
}
body {
  background: rgba(247, 247, 247, 1) !important ;
}
h1 {
  font-family: "TT Octosquares";
  font-size: 72px !important;
  font-weight: 400 !important;
  line-height: 86.4px !important;
  letter-spacing: 0em !important;
}

h2 {
  font-family: "TT Octosquares";
  font-size: 50px !important;
  font-weight: 400 !important;
  line-height: 70px !important;
  letter-spacing: -0.01em !important;
}
h3 {
  font-family: "TT Octosquares";
  font-size: 48px !important;
  font-weight: 400 !important;
  line-height: 67px !important;
  letter-spacing: -0.01em !important;
}
p {
  font-family: "Plus Jakarta Sans";
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 28.8px !important;
  letter-spacing: 0em !important;
}
ul,
ol {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}
a {
  text-decoration: none !important;
  color: var(--main-black) !important;
}
[dir="rtl"] h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
button {
  font-family: "Cairo", sans-serif !important;
}
[dir="rtl"] p {
  line-height: 28.8px !important;
}
/* ===================================== */
.p-title {
  font-family: "Plus Jakarta Sans" !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  letter-spacing: 0em !important;
  color: #151515 !important;
  text-transform: uppercase !important;
}
[dir="rtl"] .p-title {
  font-family: "Cairo", sans-serif !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  letter-spacing: 0em !important;
  color: #151515 !important;
  text-transform: uppercase !important;
}
.background-set {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.navbar-font {
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}
.main-btn {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0px !important;
  gap: 10px !important;
  width: 172px !important;
  height: 58px !important;
  background: linear-gradient(180deg, #a4c717 0%, #d6db01 100%);
  font-family: "Plus Jakarta Sans";
  font-size: 16px !important;
  font-weight: bolder !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  border-radius: 8px !important;
  color: var(--main-black);
}

.secondary-btn {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0px !important;
  gap: 10px !important;
  width: 172px !important;
  height: 58px !important;
  background: #272727;
  font-family: "Plus Jakarta Sans";
  font-size: 16px !important;
  font-weight: bolder !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  border-radius: 8px !important;
  color: var(--main-white);
}
.secondary-btn svg,
.main-btn svg {
  width: 24px;
  height: 24px;
}
.title {
  text-align: center;
  margin-bottom: 84px;
}
.title h1 {
  font-family: "TT Octosquares";
  font-size: 50px;
  font-weight: bold;
  line-height: 70px;
  letter-spacing: -0.01em;
  text-align: center;
  margin-bottom: 16px;
  text-transform: capitalize;
}
.cursor-dot,
.cursor-dot-outline {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  opacity: 1;
  transition: opacity 0.3s ease-in-out, transform 0, 3s ease-in-out;
}
.cursor-dot {
  width: 15px;
  height: 15px;
  background-color: #d6db01;
}
.cursor-dot-outline {
  width: 40px;
  height: 40px;
  background-color: #a4c717;
}
@media screen and (max-width: 1230px) {
  .cursor-dot,
  .cursor-dot-outline {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .cursor-dot,
  .cursor-dot-outline {
    display: none;
  }
  h1 {
    font-size: 42px !important;
  }
  h2 {
    font-size: 40px !important;
  }
  h3 {
    font-size: 40px !important;
  }
  p {
    font-size: 18px !important;
  }
}
/* =================================Small Devices====================== */
@media screen and (max-width: 575px) {
  h1 {
    font-weight: 400 !important;
    font-size: 28px !important;
    line-height: 120% !important;
  }
  h2 {
    font-size: 20px !important;
  }
  h3 {
    font-size: 20px !important;
  }
  p {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 150% !important;
  }
  .cursor-dot,
  .cursor-dot-outline {
    display: none;
  }
  .title {
    margin-bottom: 22px;
  }
  .title h1 {
    font-size: 24px !important;
  }
}
