.service-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  isolation: isolate;
  height: 506px;
  width: 337px;
}
.service-card-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  margin-bottom: 23px;
  width: 90%;
  height: 94px;
  background: #ffffff;
  box-shadow: -10px 10px 0px 0px rgba(247, 247, 247, 0.7);
  -webkit-box-shadow: -10px 10px 0px 0px rgba(247, 247, 247, 0.7);
  -moz-box-shadow: -10px 10px 0px 0px rgba(247, 247, 247, 0.7);
}
[dir="rtl"] .service-card-info {
  box-shadow: 10px 10px 0px 0px rgba(247, 247, 247, 0.7);
  -webkit-box-shadow: 10px 10px 0px 0px rgba(247, 247, 247, 0.7);
  -moz-box-shadow: 10px 10px 0px 0px rgba(247, 247, 247, 0.7);
}
.service-card:hover,
.service-card:hover .service-card-info {
  box-shadow: -10px 10px 0px 0px #a4c717;
  -webkit-box-shadow: -10px 10px 0px 0px #a4c717;
  -moz-box-shadow: -10px 10px 0px 0px #a4c717;
  transition: all 0.5s ease;
}
/* ===========================Medium Devices============= */
@media screen and (max-width: 992px) {
  .service-card {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .service-card {
    width: 100%;
  }
}
