.about-us .about-us-sections {
  display: flex;
  flex-direction: column;
  gap: 145px;
}
.about-us .about-us-s1 {
  height: 150vh;
  background: #191814;
  position: relative;
}
.about-us .about-us-s1 .container {
  height: 100%;
}
.about-us-s1-data {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  height: 100%;
  gap: 125px;
}
.about-us .about-us-s1 .about-us-s1-img {
  height: 748px;
  display: flex;
  align-items: center;
  background: #2b2924;
  position: relative;
}
.about-us .about-us-s1 .about-us-s1-img img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -58px;
  left: 58px;
}
.about-us .about-us-s1 .about-us-s1-info {
  display: flex;
  flex-direction: column;
}
.about-us .about-us-s1 .about-us-s1-info p,
.about-us .about-us-s1 .about-us-s1-info h2 {
  color: #f7f7f7;
}
.about-us .about-us-s1 .about-us-s1-info h2 {
  margin-bottom: 32px;
}
#about-us-s2-GRAPHICIMG,
#about-us-s1-GRAPHICIMG1 {
  width: 56px;
  height: 186px;
  bottom: 0;
  position: absolute;
}
#about-us-s1-GRAPHICIMG2 {
  width: 56px;
  height: 186px;
  top: 0;
  right: 0;
  position: absolute;
}
[dir="rtl"] .about-us-s1-img {
  order: 1;
}
/* ======================== */
.about-us .about-us-s2 {
  position: relative;
}
.about-us .about-us-s2 .about-us-s2-data {
  display: flex;
  align-items: center;
}
.about-us-s2-img-container {
  height: 590px;
  width: 90%;
  background: #d9d9d9;
  position: relative;
}
.about-us
  .about-us-s2
  .about-us-s2-data
  .about-us-s2-img-container
  .about-us-s2-img {
  height: 590px;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../../assets/Img/aboutImg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: #f7f7f7;
  position: absolute;
  top: -54px;
  left: 45px;
}
.about-us-s2-data .about-us-s2-img h2 {
  margin-bottom: 32px;
}
.about-us-s2-data .about-us-s2-img p {
  padding: 0 90px;
}
[dir="rtl"]
  .about-us
  .about-us-s2
  .about-us-s2-data
  .about-us-s2-img-container
  .about-us-s2-img {
  left: 0;
  right: 45px;
}
/* ======================== */
.about-us .about-us-s3 {
  height: 150vh;
  position: relative;
}
.about-us .about-us-s3 .container {
  height: 100%;
}
.about-us-s3-data {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  height: 100%;
  gap: 125px;
  height: 100%;
}
.about-us .about-us-s3 .about-us-s3-img {
  height: 748px;
  display: flex;
  align-items: center;
  background: #dae7a6;
  position: relative;
  order: 2;
}
.about-us .about-us-s3 .about-us-s3-img img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -58px;
  left: -58px;
}
.about-us .about-us-s3 .about-us-s3-info {
  order: 1;
  position: relative;
}
.about-us .about-us-s3 .about-us-s3-info p,
.about-us .about-us-s3 .about-us-s3-info h2 {
  color: #191814;
}
.about-us .about-us-s3 .about-us-s3-info h2 {
  margin-bottom: 32px;
}
#about-us-s3-GRAPHICIMG1 {
  top: -100px;
  right: 0;
  width: 56px;
  height: 186px;
  position: absolute;
}
#about-us-s3-GRAPHICIMG2 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 56px;
  height: 186px;
}
[dir="rtl"] .about-us-s3-img img {
  left: 0 !important;
  right: -58px;
}
@media screen and (max-width: 575px) {
  .about-us .about-us-sections {
    gap: 50px;
  }
  .about-us .about-us-s1 {
    height: 100%;
    padding-bottom: 57px;
  }
  .about-us-s1-data {
    grid-template-columns: repeat(1, 1fr);
    gap: 0px;
    height: auto;
  }
  .about-us .about-us-s1 .about-us-s1-img {
    height: auto;
    background: none;
    order: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about-us .about-us-s1 .about-us-s1-img img {
    top: 0 !important;
    left: 0 !important;
    height: auto;
    position: relative;
    width: 337px;
  }
  .about-us .about-us-s3 {
    height: 100%;
  }
  .about-us .about-us-s3 .about-us-s3-info h2,
  .about-us .about-us-s1 .about-us-s1-info h2 {
    margin-bottom: 16px;
    text-align: center;
  }
  .about-us-s2-data .about-us-s2-img p {
    padding: 0 20px;
  }
  .about-us-s2-img-container {
    width: 100% !important;
  }
  .about-us
    .about-us-s2
    .about-us-s2-data
    .about-us-s2-img-container
    .about-us-s2-img {
    justify-content: start;
    top: 0px;
    left: 0px;
    right: 0 !important;
    width: 100%;
  }
  .about-us-s3-data {
    grid-template-columns: 1fr;
    gap: 0px;
  }
  .about-us .about-us-s3 .about-us-s3-img {
    height: 500px;
  }
  .about-us .about-us-s3 .about-us-s3-img img {
    top: 0 !important;
    left: 0 !important;
  }
  #about-us-s2-GRAPHICIMG,
  #about-us-s1-GRAPHICIMG1 {
    display: none;
  }
  [dir="rtl"] .about-us-s3-img img {
    right: 0;
  }
}
